<template>
  <div>
    <el-drawer :visible.sync="params.show" :before-close="close" size="30%" :modal="false"  :with-header="false" @open="openDrawer">
      <el-form ref="ruleFormRef" :model="ruleForm" label-width="60px">
        <el-form-item label="备注" prop="comment">
          <el-input type="textarea"
            :autosize="{ minRows: 8, maxRows: 4}"
            placeholder="请输入内容"
            v-model="ruleForm.comment">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import { setRepairComment } from '@/api/repair/repair'
export default {
  components: {
    
  },
  data() {
    return {
      ruleForm: {
        id: '',
        comment: ''
      }
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.$emit('close')
    },
    openDrawer () {
      this.ruleForm = this.params.data
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = await setRepairComment(this.ruleForm)
        if (result.success) {
          this.$message.success('已完成')
          this.close()
        }
      })
    },
  },
  computed: {
  },
}
</script>
<style scoped lang="less">

</style>
