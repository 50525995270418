<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>报修管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row>
      <el-col :span="24">
        <el-select @change="selectQueryChange" v-model="queryInfo.state" placeholder="请选择">
          <el-option
            v-for="item in stateValue"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    
    <table-ui
      :propList="propList"
      :operationProps="operationProps"
      :listData="repairList"
      v-bind="otherProps"
      :listCount="repairTotal"
      v-model="queryInfo"
      @page="pageChange"
    >
      <template #brandSlot="scope">{{ scope.row.brand | brandFormat }}</template>
      <template #brandTypeSlot="scope">{{ scope.row.brand_type | brandTypeFormat }}</template>
      <template #stateSlot="scope">
        <el-select @change="selectChange(scope.row)" v-model="scope.row.state" placeholder="请选择">
          <el-option
            v-for="item in stateValue"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </template>
      <template #operation="scope">
        <span @click="editClick(scope.row)" style="margin-right: 10px;cursor: pointer;">备注</span>
      </template>
    </table-ui>
    <edit-repair-drawer :params="editInfo" @close="editDrawerlose"></edit-repair-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TableUi from '@/components/table-ui/table-ui.vue'
import { propList, operationProps, otherProps } from './config/repairConfig.js'
import { setRepairState } from '@/api/repair/repair'
import editRepairDrawer from './cnps/edit-repair-drawer.vue'
export default {
  data() {
    return {
      propList,
      operationProps,
      otherProps,
      stateValue: [
        { label: '待处理', value: 1 },
        { label: '处理中', value: 2 },
        { label: '已处理', value: 3 },
      ],
      queryInfo: {
        cur: 1,
        size: 20,
        state: 1
      },
      editInfo: {
        show: false,
        data: null,
      }
    }
  },
  components: {
    TableUi,
    editRepairDrawer
  },
  mounted() {
    this.getDataList()
  },
  computed: {
    ...mapState('repair', ['repairList', 'repairTotal'])
  },
  methods: {
    ...mapActions('repair', ['getRepairList']),
    getDataList () {
      this.getRepairList(this.queryInfo)
    },
    pageChange(ev) {
      this.queryInfo.cur = ev.cur
      this.queryInfo.size = ev.size
      this.getDataList()
    },
    async selectChange(row) {
      const result = await setRepairState({ id: row.id, state: row.state })
      if (result.success) {
        this.$message.success('修改成功')
        this.getDataList()
      }
    },
    async selectQueryChange() {
      this.queryInfo.cur = 1
      this.getDataList()
    },
    editClick (row) {
      this.editInfo.data = { id: row.id, comment: row.comment || '' }
      this.editInfo.show = true
    },
    editDrawerlose () {
      this.editInfo.show = false
      this.getDataList()
    }
  },
  
}
</script>

<style lang="less" scoped>

</style>